<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">

          <el-form-item prop="target">
            <el-input v-number-format v-model="scale.target" :disabled="disabled">
              <template slot="prepend">Цель</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Значение коэффициента при достижении целевых значений"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_min">
            <el-input type="number" v-model="scale.pay_min" :disabled="disabled">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_max">
            <el-input type="number" v-model="scale.pay_max" :disabled="disabled">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Факт"
    >
      <el-input v-number-format v-model="scale.fact" :disabled="disabled">
        <template slot="prepend">Факт</template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    return {
      scale:{
        target: null,
        pay_min: null,
        pay_max: null,
        fact: null,
      },

      rules: {}


    }
  },

  methods: {

  }
}
</script>

<style>

</style>